import React, { useState } from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

interface EssentialFurnitureProps {
  data: any;
}

const EssentialFurniture: React.FC<EssentialFurnitureProps> = () => {
  const [activeTab, setActive] = useState(0);
  const data1 = {
    heading: 'Explore Essential Home Furniture',
    subheading: 'Design Your Home Decor, Your Way',
    categories: [
      {
        category: 'Top Categories',
        products: [
          {
            name: 'Sofa Sets',
            price: 'From ₹47,989',
            url: 'sofa-sets',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Ffabric-sofa%2Fnew-osbert-set%2Fnew-logo%2F1-750x650.jpg',
              alt: 'small home decor items online india',
            },
          },
          {
            name: 'Beds',
            price: 'From ₹7,199',
            url: 'beds',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fbed-with-storage%2Fadolph-bed-with-side-storage%2Frevised%2Frevised%2Fhoney%2Fupdated%2Fhoney%2Fnew-logo%2F1-750x650.jpg',
              alt: 'buy home decorative items for living room',
            },
          },
          {
            name: 'Dining Table Sets',
            price: 'From ₹32,989',
            url: 'dining-table-sets',
            image: {
              src: 'https://images.woodenstreet.de/image/data/dining-set/6-seater/trish-advait-6-seater-dining-table-set-with-bench/updated/new-update/4.jpg',
              alt: 'room decoration items for home',
            },
          },
          {
            name: 'Lamps & Lightings',
            price: 'From ₹538',
            url: 'lamps-and-lighting',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2FChina+Furniture%2FLight%2F31-10-2023%2FWS-CHF-SA-75777%2FLooks%2F3-750x650.jpg',
              alt: 'home decoration items shop near me',
            },
          },
          {
            name: 'Sofa Cum Beds',
            price: 'From ₹6,989',
            url: 'sofa-cum-beds',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fsofa-beds%2Fsereta-sofa-cum-bed%2Fupdated%2Fhoney%2Fupdated%2Fnew-logo%2F3-750x650.jpg',
              alt: 'buy room decor items india',
            },
          },
          {
            name: 'Coffee Tables',
            price: 'From ₹2,499',
            url: 'coffee-tables',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fcoffee-table%2Fpetlin-coffee-table-revised%2Frevised%2Fhoney%2Frevised%2Fnew-logo%2F1-750x650.jpg',
              alt: 'best home accessories online shopping',
            },
          },
          {
            name: 'Wardrobes',
            price: 'From ₹7,499',
            url: 'wardrobes',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fwardrobe%2Fcambrey-double-door-wardrobe-with-mirror%2Frevised%2Fhoney%2Fupdated%2Fnew-logo%2F1-750x650.jpg',
              alt: 'home decore for living room',
            },
          },
          {
            name: 'Home Temple',
            price: 'From ₹1,423',
            url: 'home-temple',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fhome-temple%2Fmaurya-home-temple%2Frevised%2Fhoney%2Fupdated%2Fnew-logo%2F1-750x650.jpg',
              alt: 'cheap home decor products under 500',
            },
          },
        ],
      },
      {
        category: 'Living Room',
        products: [
          {
            name: '3 Seater Sofas',
            price: 'From ₹9,899',
            url: 'three-seater-sofas',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Ffabric-sofa%2Fadrick-3-seater-sofa%2Fproduct%2FDark-olive-green%2Fnew-logo%2F1-750x650.jpg',
              alt: 'small home decor items online india',
            },
          },
          {
            name: 'L Shape Sofa',
            price: 'From ₹31,449',
            url: 'l-shaped-sofas',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Ffabric-sofa%2Foasis-l-shape-sectional-fabric-sofa-cotton-jade-ivory%2Fnew-logo%2F1-750x650.jpg',
              alt: 'buy home decorative items for living room',
            },
          },
          {
            name: 'Lounge Chairs',
            price: 'From ₹5,999',
            url: 'lounge-chairs',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Flounge-chairs%2FAdoree-lounge-arm-chair-wanlut-finish%2Fnewwww%2Frose-vineyarrd%2Fnew-logo%2F1-750x650.jpg',
              alt: 'home decoration items shop near me',
            },
          },
          {
            name: 'Bookshelves',
            price: 'From ₹1,899',
            url: 'bookshelves',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fbook-shelves%2Fcagney-bookshelf-revised%2Fhoney%2Fupdated%2Fnew-logo%2F1-750x650.jpg',
              alt: 'home decore for living room',
            },
          },
          {
            name: 'Coffee and Center Tables',
            price: 'From ₹2,499',
            url: 'coffee-tables',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fcoffee-table%2Fvesta-coffee-table-revised%2Frevised%2FHoney+Finish%2FCream+Blossom%2Fupdated+new%2Fnew-logo%2F1-750x650.jpg',
              alt: 'buy room decor items india',
            },
          },
          {
            name: 'Tv Units',
            price: 'From ₹1,699',
            url: 'tv-units',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Ftv-units%2Fmelvina-tv-unit%2Frevised%2Fhoney%2Fupdated%2F1-750x650.jpg',
              alt: 'best home accessories online shopping',
            },
          },
          {
            name: 'Shoe Racks',
            price: 'From ₹1,750',
            url: 'shoe-racks',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2FShoe-rack-mdf%2Flouis-multi-storage-shoe-rack-exotic-teak-finish%2Fupdated%2Fnew+images%2Fnew-logo%2F1-750x650.jpg',
              alt: 'best home accessories online shopping',
            },
          },
          {
            name: 'Side Tables',
            price: 'From ₹699',
            url: 'side-tables',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fside-end-table-mdf%2Ffacile-square-side-end-table%2Fexotic-frosty%2Fupdated%2Fupdated%2Fnew-logo%2F9-750x650.jpg',
              alt: 'cheap home decor products under 500',
            },
          },
        ],
      },
      {
        category: 'Bedroom',
        products: [
          {
            name: 'King Size Beds',
            price: 'From ₹11,300',
            url: 'king-size-beds',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fbed-with-storage%2Fbrixton-sheesham-wood-bed-with-storage-drawers-king-size-honey-finish%2Fnew-logo%2F1-750x650.jpg',
              alt: 'small home decor items online india',
            },
          },
          {
            name: 'Queen Size Bed',
            price: 'From ₹10,999',
            url: 'queen-size-beds',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fbed-with-storage%2Fferguson-bed-with-storage%2Fqueen%2Fwalnut%2Fupdated%2Fnew-logo%2F1-750x650.jpg',
              alt: 'buy home decorative items for living room',
            },
          },
          {
            name: 'Wardrobes',
            price: 'From ₹7,499',
            url: 'wardrobes',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fwardrobe%2Fcambrey-2-door-multi-utility-wardrobe%2Fhoney%2Fupdated%2Fnew-logo%2F1-750x650.jpg',
              alt: 'room decoration items for home',
            },
          },
          {
            name: 'Bed Side Table',
            price: 'From ₹2,326',
            url: 'bedside-tables',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fbedside-tables%2Fadolph-bedside-table%2Frevised%2Fwalnut%2Fupdated%2Fnew-logo%2F1-750x650.jpg',
              alt: 'buy room decor items india',
            },
          },
          {
            name: 'Chest Of Drawers',
            price: 'From ₹3,499',
            url: 'chest-of-drawers',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fchest-of-drawers-ws%2Fjett-chest-of-drawers%2Frevised%2Fhoney%2Fupdated%2Fnew-logo%2F1-750x650.jpg',
              alt: 'home decoration items shop near me',
            },
          },
          {
            name: 'Dressing Tables',
            price: 'From ₹1,899',
            url: 'dressing-tables',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fdressing-tables-mdf%2Fremy%2Fexotic%2Fupdated%2Fupdated%2Fnew-logo%2F1-750x650.jpg',
              alt: 'home decore for living room',
            },
          },
          {
            name: 'Divan Beds',
            price: 'From ₹21,179',
            url: 'divan-beds',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fdivans%2Fangelica-divan%2Frevised%2Fhoney%2Fupdated%2Fnew-logo%2F1-750x650.jpg',
              alt: 'best home accessories online shopping',
            },
          },
          {
            name: 'Mattress',
            price: 'From ₹4,785',
            url: 'mattress',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fmattress%2Fupdated%2Fortho-memory-mattress%2Fupdated%2Fsingle%2F6-inch%2Fupdated%2Fbamboobliss1%2Frevised%2Flook-06-copy-750x650.jpg',
              alt: 'cheap home decor products under 500',
            },
          },
        ],
      },
      {
        category: 'Dining Room',
        products: [
          {
            name: '6 Seater Dining Table Sets',
            price: 'From ₹32,989',
            url: '6-seater-dining-table-sets',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fdining-set%2F6-seater%2Fjanet-6-seater-dining-set-revised%2Fwithout-bench%2Fwith-cushion%2Fhoney%2Fupdated%2Fupdated%2Fnew-logo%2Fnew-logo%2F3-750x650.jpg',
              alt: 'small home decor items online india',
            },
          },
          {
            name: '4 Seater Dining Table Sets',
            price: 'From ₹13,799',
            url: '4-seater-dining-table-sets',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fdining-set%2Fcamden%2F4-seater%2Fhoney%2Fnew-logo%2F1-750x650.jpg',
              alt: 'best home accessories online shopping',
            },
          },
          {
            name: 'Bar Cabinets',
            price: 'From ₹6,989',
            url: 'bar-cabinets',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fbar-cabinets%2Fauric-large-bar-cabinet-revised%2Fhoney%2Fupdated%2Fnew-logo%2F1-750x650.jpg',
              alt: 'home decoration items shop near me',
            },
          },
          {
            name: 'Benches',
            price: 'From ₹3,999',
            url: 'benches',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fbenches%2Fdiva-ashwood-upholstered-bench-honey-finish%2F1-750x650.jpg',
              alt: 'room decoration items for home',
            },
          },
          {
            name: 'Kitchen Cabinet',
            price: 'From ₹8,499',
            url: 'kitchen-cabinet',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fkitchen-cabinet%2Fmarty-kitchen-cabinet%2Fupdated%2Fexotic-teak-frosty-white-finish%2F1-750x650.jpg',
              alt: 'cheap home decor products under 500',
            },
          },
          {
            name: 'Dining Chairs',
            price: 'From ₹3,999',
            url: 'dining-chairs',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fdining-chairs%2Fadolph-dining-chairs-set-of-2-honey-finish%2Fhoney-finish%2Fnew-logo%2F1-750x650.jpg',
              alt: 'home decore for living room',
            },
          },
          {
            name: 'Kitchen Racks',
            price: 'From ₹1,804',
            url: 'kitchen-racks',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fkitchen-rack%2Fmedha-solid-wood-kitchen-rack-teak-finish%2Fnew-images%2Fnew-logo%2F3-750x650.jpg',
              alt: 'buy room decor items india',
            },
          },
          {
            name: 'Dining Table',
            price: 'From ₹6,989',
            url: 'dining-tables',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fdining-set%2F8-seater%2Fjanet-8-seater-dining-set%2Fupdated%2Fhoney-finish%2Fupdated%2F1-750x650.jpg',
              alt: 'buy home decorative items for living room',
            },
          },
        ],
      },
      {
        category: 'Study Room',
        products: [
          {
            name: 'Study Tables',
            price: 'From ₹1,399',
            url: 'study-tables',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fstudy-table%2Fadolph-study-table%2Fresize%2Fhoney-finish%2Fnew-logo%2F1-750x650.jpg',
              alt: 'home decoration items shop near me',
            },
          },
          {
            name: 'Office Tables',
            price: 'From ₹8,699',
            url: 'office-tables',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Foffice-table%2Felif-work-station-table-exotic-teak-finish%2Fexotic-teak-finish%2Fnew-logo%2F1-750x650.jpg',
              alt: 'best home accessories online shopping',
            },
          },
          {
            name: 'Laptop Tables',
            price: 'From ₹499',
            url: 'laptop-tables',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Flaptop-tables%2Flance-laptop-table-revised%2Fhoney%2Fupdated%2Fnew-logo%2F1-750x650.jpg',
              alt: 'buy home decorative items for living room',
            },
          },
          {
            name: 'Wall Shelf',
            price: 'From ₹26,449',
            url: 'wall-shelves',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fwall-shelves%2Fsculpa-wall-shelf%2Fupdated%2Fwalnut-finish%2Fnew-logo%2F1-750x650.jpg',
              alt: 'room decoration items for home',
            },
          },
          {
            name: 'Office Chairs',
            price: 'From ₹3,999',
            url: 'office-chairs',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fda-urban%2Fveneto-brown-high-back-office-revolving-chair%2Fupdated%2F1-750x650.jpg',
              alt: 'home decore for living room',
            },
          },
          {
            name: 'File Cabinets',
            price: 'From ₹4,300',
            url: 'file-cabinets',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fbookshelves-mdf%2Faltus-bookshelf%2Faltus-bookshelf-exotic-teak-frosty-white-finish%2Fupdated%2Fnew-logo%2F1-750x650.jpg',
              alt: 'buy room decor items india',
            },
          },
          {
            name: 'Study Chairs',
            price: 'From ₹3,999',
            url: 'study-chairs',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2FGREEN+SOUL%2Fjupiter-go-high-back-evolutionary-ergonomic-office-chair-grey%2FGS-1-750x650.jpg',
              alt: 'room decoration items for home',
            },
          },
          {
            name: 'Kids Study Tables',
            price: 'From ₹3,705',
            url: 'kids-study-table',
            image: {
              src: 'https://images.woodenstreet.de/image/cache/data%2Fboing+kids%2Ftimeless-study-table-yellow%2FBK-1-750x650.jpg',
              alt: 'cheap home decor products under 500',
            },
          },
        ],
      },
    ],
  };
  const setTab = (e: any, i: number) => {
    e.preventDefault();
    setActive(i);
  };
  return (
    <>
      <section className={styles.essentftursection}>
        <div className="home-container-1600">
          <div className={styles.essentfturinner}>
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.essentfturheading}>
                  {/* <h2>{data?.title}</h2>
                <p>{data?.subtitle}</p> */}
                  <h2>{data1?.heading}</h2>
                  <p>{data1?.subheading}</p>
                </div>
              </div>
            </div>
            <div className={styles.essentfturcontent}>
              <ul className={styles['tabs-nav']}>
                {data1.categories.map((item, i) => (
                  <li
                    key={'li' + i}
                    className={`${styles['tabs-item']} ${i === activeTab && styles['active']}`}
                  >
                    <Link
                      href={'#'}
                      className={`${styles['tabs-btn']}`}
                      onClick={(e) => setTab(e, i)}
                    >
                      {item.category}
                    </Link>
                  </li>
                ))}
                {/* <li className={`${styles['tabs-item']} ${styles['active']}`}>
                  {' '}
                  <Link href={'#'} className={`${styles['tabs-btn']}`}>
                    Top Categories
                  </Link>
                </li>
                <li className={`${styles['tabs-item']}`}>
                  <Link href={'#'} className={`${styles['tabs-btn']}`}>
                    Living Room
                  </Link>
                </li>
                <li className={`${styles['tabs-item']}`}>
                  {' '}
                  <Link href={'#'} className={`${styles['tabs-btn']}`}>
                    Bedroom
                  </Link>
                </li>
                <li className={`${styles['tabs-item']}`}>
                  <Link href={'#'} className={`${styles['tabs-btn']}`}>
                    Dining Room
                  </Link>
                </li>{' '}
                <li className={`${styles['tabs-item']}`}>
                  <Link href={'#'} className={`${styles['tabs-btn']}`}>
                    Storage Room
                  </Link> 
                </li>*/}
              </ul>
              <div className="wdRow wdgx-3">
                {data1?.categories?.[activeTab]?.products?.map(
                  (product: any, index: number) => (
                    <div
                      className="wdCol-lg-3 wdCol-md-3 wdCol-sm-6 wdCol-12"
                      key={index}
                    >
                      <Link
                        // href={product.category}
                        href={product.url}
                        className={styles.essentfturcard}
                      >
                        <div className={styles.essentfturimg}>
                          <figure>
                            <Image
                              // src={product.imageSrc}
                              // alt={product.alt}
                              // title={product.name}
                              src={product.image.src}
                              alt={product.image.alt}
                              title={product.name}
                              loading="eager"
                              quality={75}
                              width={445}
                              height={425}
                            />
                          </figure>
                        </div>
                        <div className={styles.essentfturtext}>
                          <h4>{product.name}</h4>
                          <p>{product.price}</p>
                          {/* <p>{product.priceRange.replace('Rs', '₹')}</p> */}
                        </div>
                      </Link>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EssentialFurniture;
